.account-balance {
  border-radius: 1rem;
}

.clickable {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 6px #ccc;
  }

}
