.sale-preview {
  @media (min-width: 62rem) {
    width: 29rem;
    vertical-align: top;
  }
}

.sale-title-link {
  text-decoration: none;
}
