.rate {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.rate-body {
  cursor: pointer;
  text-align: end;
  white-space: nowrap;
}

.rate-value {
  color: #000;
  font-weight: bold;
}
