.sale-token-input {
  margin-bottom: 0;
}

.sale-token-output {
  margin-bottom: 0.5rem;
}

.balances {
  flex-grow: 100;
}
