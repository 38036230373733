@import "src/App";

.price-history {
  @media (min-width: 60rem) {
    display: inline-block;
    width: calc(100% - 30rem);
    min-width: 28rem;
    vertical-align: top;
  }
}
